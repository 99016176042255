import { render, staticRenderFns } from "./niveaux-couverture.vue?vue&type=template&id=3012ca4e"
import script from "./niveaux-couverture.vue?vue&type=script&setup=true&lang=ts"
export * from "./niveaux-couverture.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./niveaux-couverture.vue?vue&type=style&index=0&id=3012ca4e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesNeedsBar: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/needs-bar/needs-bar.vue').default,Step: require('/vercel/path0/components/Step.vue').default})
